import * as React from 'react';
import {IImageDimension} from '../../../../types/productDef';
import classNames from 'classnames';
import style from './ProductVideoItem.scss';
import {getMediaUrl} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {ImageMode} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {IVideoFile} from '@wix/wixstores-graphql-schema/dist/es/src';
import loadable from '@loadable/component';
import {IMediaItem} from '../../../../types/app-types';
import {ReactPlayableProps} from 'react-playable/dist/src';

export interface ProductVideoProps {
  mediaItem: IMediaItem;
  imageMode: ImageMode;
  mountedToDOM: boolean;
  video: IVideoFile;
  dimensions: IImageDimension;
  index: number;
  onAddVideo(player: any, index: number): any;
}

export enum DataHook {
  productPageMediaVideo = 'product-page-media-video',
}

const ReactPlayable = loadable<ReactPlayableProps>(
  () => import(/* webpackChunkName: "ReactPlayable" */ 'react-playable/dist/src'),
  {
    resolveComponent: (components) => components.ReactPlayable,
  }
);

export const ProductVideoItem: React.FunctionComponent<ProductVideoProps> = ({
  mediaItem,
  imageMode,
  mountedToDOM,
  video,
  dimensions,
  index,
  onAddVideo,
}) => {
  const WIXVIDEO_BASEURL = 'https://video.wixstatic.com/';

  const getMainImageUrl = (targetDimensions: {width: number; height: number}, isSSR: boolean): string => {
    return getMediaUrl(mediaItem, targetDimensions, {
      isSSR,
      imageMode,
    });
  };

  const posterUrl = getMainImageUrl(dimensions, mountedToDOM);
  const videoContainerClasses = classNames([style.media, style.mediaVideo]);

  return (
    <div
      data-hook={DataHook.productPageMediaVideo}
      className={videoContainerClasses}
      style={{height: dimensions.height}}>
      <ReactPlayable
        onInit={(player: any) => onAddVideo(player, index)}
        poster={posterUrl}
        width={dimensions.width}
        height={dimensions.height}
        src={`${WIXVIDEO_BASEURL}${video.url}`}
      />
    </div>
  );
};
